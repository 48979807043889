var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-box"},[_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"form":_vm.form,"id":"formLogin"}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'loginName',
          { initialValue: '', rules: _vm.validatorRules.loginName.rules },
        ]),expression:"[\n          'loginName',\n          { initialValue: '', rules: validatorRules.loginName.rules },\n        ]"}],attrs:{"size":"large","type":"text","placeholder":"請輸入賬號"},on:{"change":_vm.handleLoginName}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          { initialValue: '', rules: _vm.validatorRules.password.rules },
        ]),expression:"[\n          'password',\n          { initialValue: '', rules: validatorRules.password.rules },\n        ]"}],attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":"密碼"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('div',{ref:"dragDiv",staticClass:"drag-wrapper"},[_c('div',{staticClass:"drag_bg"}),_c('div',{staticClass:"drag_text f14"},[_vm._v(_vm._s(_vm.confirmWords))]),_c('div',{ref:"moveDiv",staticClass:"handler handler_bg",class:{ handler_ok_bg: _vm.confirmSuccess },on:{"mousedown":function($event){return _vm.mousedownFn($event)}}})])]),_c('a-form-item',{staticStyle:{"margin-top":"24px"}},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","disabled":_vm.loginBtn},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.loginBtn),callback:function ($$v) {_vm.loginBtn=$$v},expression:"loginBtn"}},[_vm._v("登錄")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }