<template>
  <div class="main-box">
    <a-form
      :form="form"
      class="user-layout-login"
      ref="formLogin"
      id="formLogin"
    >
      <a-form-item>
        <a-input
          size="large"
          v-decorator="[
            'loginName',
            { initialValue: '', rules: validatorRules.loginName.rules },
          ]"
          type="text"
          placeholder="請輸入賬號"
          @change="handleLoginName"
        >
          <a-icon
            slot="prefix"
            type="user"
            :style="{ color: 'rgba(0,0,0,.25)' }"
          />
        </a-input>
      </a-form-item>

      <a-form-item>
        <a-input
          v-decorator="[
            'password',
            { initialValue: '', rules: validatorRules.password.rules },
          ]"
          size="large"
          type="password"
          autocomplete="false"
          placeholder="密碼"
        >
          <a-icon
            slot="prefix"
            type="lock"
            :style="{ color: 'rgba(0,0,0,.25)' }"
          />
        </a-input>
      </a-form-item>

      <!-- <a-form-item>
        <div style="display:flex;">
          <a-input
            v-decorator="['code',validatorRules.code]"
            size="large"
            :maxLength="6"
            style="width:80%"
            placeholder="验证码"
          ></a-input>
          <a-button
            :disabled="!codeShow"
            type="primary"
            size="large"
            style="margin-left:10px"
            @click="handleCodeup"
          >
            <span v-if="codeShow">{{coded}}</span>
            <span v-else>{{count}}秒后重试</span>
          </a-button>
        </div>
        <span v-if="isSend" style="color:#fff;">已将验证码发送到{{phoneEncryption}}，请注意查收</span>
      </a-form-item> -->

      <a-form-item>
        <div class="drag-wrapper" ref="dragDiv">
          <div class="drag_bg"></div>
          <div class="drag_text f14">{{ confirmWords }}</div>
          <div
            ref="moveDiv"
            @mousedown="mousedownFn($event)"
            :class="{ handler_ok_bg: confirmSuccess }"
            class="handler handler_bg"
          ></div>
        </div>
      </a-form-item>

      <a-form-item style="margin-top:24px">
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          class="login-button"
          @click.stop.prevent="handleSubmit"
          v-model="loginBtn"
          :disabled="loginBtn"
          >登錄</a-button
        >
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import md5 from "md5";
import api from "@/api";
import { mapActions } from "vuex";
import { timeFix } from "@/utils/util";
import Vue from "vue";
import {
  ACCESS_TOKEN,
  ENCRYPTED_STRING,
  USER_INFO,
} from "@/store/mutation-types";
import { putAction, postAction, getAction } from "@/api/manage";
import { encryption, getEncryptedString } from "@/utils/encryption/aesEncrypt";
import store from "@/store/";

export default {
  components: {
  },
  data() {
    return {
      customActiveKey: "tab1",
      systemTitle: window.SYS_TITLE,
      systemUrl: window.SYS_URL,
      loginBtn: true,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      encryptedString: {
        key: "",
        iv: "",
      },
      state: {
        time: 60,
        smsSendBtn: false,
      },
      validatorRules: {
        loginName: {
          rules: [
            { required: true, message: "請輸入賬號!" },
            // { validator: this.handleLoginName }
          ],
        },
        password: {
          rules: [
            { required: true, message: "請輸入密碼!", validator: "click" },
          ],
        },
        code: {
          rules: [
            {
              required: true,
              message: "请输入正确的验证码!",
              pattern: "^[0-9]{6}$",
            },
          ],
        },
      },
      verifiedCode: "",
      inputCodeContent: "", // 20200510 cfm: 为方便测试，不输入验证码可 ""-->"xxxx"
      inputCodeNull: true,
      departList: [],
      departVisible: false,
      departSelected: "",
      currentUsername: "",
      validate_status: "",
      currdatetime: "",
      randCodeImage: "",
      requestCodeSuccess: false,
      coded: "获取验证码",
      count: "",
      timer: null,
      codeShow: true,
      phoneEncryption: "",
      phonenum: "",
      isSend: false,

      beginClientX: 0, // 距离屏幕左端距离
      mouseMoveStata: false, // 触发拖动状态  判断
      maxwidth: "", // 拖动最大宽度，依据滑块宽度算出来的
      confirmWords: "拖动滑块验证", // 滑块文字
      confirmSuccess: false,
    };
  },
  created() {
    this.currdatetime = new Date().getTime();
    Vue.ls.remove(ACCESS_TOKEN);
    this.getRouterData();
  },
  // 拖拽
  mounted() {
    // // 根据滑块宽度计算可拖动最大宽度
    this.maxwidth =
      this.$refs.dragDiv.clientWidth - this.$refs.moveDiv.clientWidth;
    // 监听手指的触摸事件
    document
      .getElementsByTagName("html")[0]
      .addEventListener("mousemove", this.mouseMoveFn);
    // 监听手指离开事件
    document
      .getElementsByTagName("html")[0]
      .addEventListener("mouseup", this.moseUpFn);
  },
  methods: {
    ...mapActions(["Login", "Logout"]),
    handleSubmit() {
      const that = this;
      const loginParams = {};
      this.form.validateFields((err, values) => {
        if (!err) {
          loginParams.loginName = values.loginName;
          loginParams.password = md5(values.password);
          // loginParams.phonenum = this.phonenum;
          console.log(loginParams);
          that
            .Login(loginParams)
            .then((res) => {
              this.departConfirm(res);
            })
            .catch((err) => {
              that.requestFailed(err);
            });
        }
      });
    },
    loginSuccess() {
      this.$router.push({ path: "/" });
      this.$notification.success({
        message: "歡迎",
        description: `${timeFix()}，歡迎回來`,
      });
    },
    cmsFailed(err) {
      this.$notification.error({
        message: "登錄失敗",
        description: err,
        duration: 4,
      });
    },
    requestFailed(err) {
      this.$notification.error({
        message: "登錄失敗",
        description:
          ((err.response || {}).data || {}).message ||
          err.message ||
          "請求出現錯誤，請稍後再試",
        duration: 4,
      });
      this.loginBtn = true;
      this.initStatus();
    },
    generateCode(value) {
      this.verifiedCode = value.toLowerCase();
    },
    departConfirm(res) {
      if (res.code == 200) {
        this.loginSuccess();
      } else {
        this.requestFailed(res);
        this.Logout();
      }
    },
    getRouterData() {
      this.$nextTick(() => {
        if (this.$route.params.username) {
          this.form.setFieldsValue({
            username: this.$route.params.username,
          });
        }
      });
    },
    // 获取密码加密规则
    getEncrypte() {
      const encryptedString = Vue.ls.get(ENCRYPTED_STRING);
      if (encryptedString == null) {
        getEncryptedString().then((data) => {
          this.encryptedString = data;
        });
      } else {
        this.encryptedString = encryptedString;
      }
    },
    // 获取验证码
    async handleCodeup() {
      const loginName = this.form.getFieldValue("loginName");
      let phonenum;
      if (loginName) {
        if (!phonenum) {
          this.$message.warning("未设置手机号，请联系管理员");
        } else {
        }
        this.phonenum = phonenum;
      } else {
        this.$message.warning("请先输入用户名");
      }
    },
    handleCountdown() {
      // 点击获取验证码
      const TIME_COUNT = 60; // 倒计时60秒
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.codeShow = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.codeShow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    //
    handleLoginName() {
      this.isSend = false;
      this.codeShow = true;
      clearInterval(this.timer);
      this.timer = null;
    },

    //验证成功函数
    mousedownFn(e) {
      if (!this.confirmSuccess) {
        e.preventDefault && e.preventDefault(); //阻止文字选中等 浏览器默认事件
        this.mouseMoveStata = true;
        this.beginClientX = e.clientX;
      }
    },
    //mousedoen 事件
    successFunction() {
      this.confirmSuccess = true;
      this.confirmWords = "验证通过";
      this.$emit("onValidation", true);
      this.loginBtn = false;
      if (window.addEventListener) {
        document
          .getElementsByTagName("html")[0]
          .removeEventListener("mousemove", this.mouseMoveFn);
        document
          .getElementsByTagName("html")[0]
          .removeEventListener("mouseup", this.moseUpFn);
      } else
        document
          .getElementsByTagName("html")[0]
          .removeEventListener("mouseup", () => {});
      document.getElementsByClassName("drag_text")[0].style.color = "#fff";
      document.getElementsByClassName("handler")[0].style.left =
        this.maxwidth + "px";
      document.getElementsByClassName("drag_bg")[0].style.width =
        this.maxwidth + "px";
    },
    //验证成功函数
    mouseMoveFn(e) {
      if (this.mouseMoveStata) {
        let width = e.clientX - this.beginClientX;
        if (width > 0 && width <= this.maxwidth) {
          document.getElementsByClassName("handler")[0].style.left =
            width + "px";
          document.getElementsByClassName("drag_bg")[0].style.width =
            width + "px";
        } else if (width > this.maxwidth) this.successFunction();
      }
    },
    //mousemove事件
    moseUpFn(e) {
      this.mouseMoveStata = false;
      var width = e.clientX - this.beginClientX;
      if (width < this.maxwidth) {
        document.getElementsByClassName("handler")[0].style.left = 0 + "px";
        document.getElementsByClassName("drag_bg")[0].style.width = 0 + "px";
      }
    },
    initStatus() {
      document.getElementsByClassName("handler")[0].style.left = 0 + "px";
      document.getElementsByClassName("drag_bg")[0].style.width = 0 + "px";
      this.mouseMoveStata = false;
      this.beginClientX = 0;
      this.confirmWords = "拖动滑块验证";
      this.confirmSuccess = false;
      this.maxwidth = "";
      this.maxwidth =
        this.$refs.dragDiv.clientWidth - this.$refs.moveDiv.clientWidth;
      // 监听手指的触摸事件
      document
        .getElementsByTagName("html")[0]
        .addEventListener("mousemove", this.mouseMoveFn);
      // 监听手指离开事件
      document
        .getElementsByTagName("html")[0]
        .addEventListener("mouseup", this.moseUpFn);
    },
  },
};
</script>

<style lang="less" scoped>
.main-box {
  width: 80% !important;
}
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
    // background-color: #fff;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #31a978;
      }
    }

    .register {
      float: right;
    }
  }
  .pic-code {
    display: inline-block;
    width: 80px;
    height: 40px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
.ant-btn-primary[disabled] {
  background-color: #fff !important;
}
.drag-wrapper {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 42px;
  line-height: 42px;
  text-align: center;
  // border-color: #d9d9d9;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.handler {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: move;
  position: absolute;
  top: 0px;
  left: 0px;
}
.handler_bg {
  background: #fff
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTEyNTVEMURGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTEyNTVEMUNGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2MTc5NzNmZS02OTQxLTQyOTYtYTIwNi02NDI2YTNkOWU5YmUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+YiRG4AAAALFJREFUeNpi/P//PwMlgImBQkA9A+bOnfsIiBOxKcInh+yCaCDuByoswaIOpxwjciACFegBqZ1AvBSIS5OTk/8TkmNEjwWgQiUgtQuIjwAxUF3yX3xyGIEIFLwHpKyAWB+I1xGSwxULIGf9A7mQkBwTlhBXAFLHgPgqEAcTkmNCU6AL9d8WII4HOvk3ITkWJAXWUMlOoGQHmsE45ViQ2KuBuASoYC4Wf+OUYxz6mQkgwAAN9mIrUReCXgAAAABJRU5ErkJggg==")
    no-repeat center;
}
.handler_ok_bg {
  background: #fff
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDlBRDI3NjVGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDlBRDI3NjRGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDphNWEzMWNhMC1hYmViLTQxNWEtYTEwZS04Y2U5NzRlN2Q4YTEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+k+sHwwAAASZJREFUeNpi/P//PwMyKD8uZw+kUoDYEYgloMIvgHg/EM/ptHx0EFk9I8wAoEZ+IDUPiIMY8IN1QJwENOgj3ACo5gNAbMBAHLgAxA4gQ5igAnNJ0MwAVTsX7IKyY7L2UNuJAf+AmAmJ78AEDTBiwGYg5gbifCSxFCZoaBMCy4A4GOjnH0D6DpK4IxNSVIHAfSDOAeLraJrjgJp/AwPbHMhejiQnwYRmUzNQ4VQgDQqXK0ia/0I17wJiPmQNTNBEAgMlQIWiQA2vgWw7QppBekGxsAjIiEUSBNnsBDWEAY9mEFgMMgBk00E0iZtA7AHEctDQ58MRuA6wlLgGFMoMpIG1QFeGwAIxGZo8GUhIysmwQGSAZgwHaEZhICIzOaBkJkqyM0CAAQDGx279Jf50AAAAAABJRU5ErkJggg==")
    no-repeat center;
}
.drag_bg {
  // background-color: #7ac23c;
  background-color: #31a978;
  height: 40px;
  width: 0px;
  border-radius: 4px;
}
.drag_text {
  position: absolute;
  top: 0px;
  width: 100%;
  text-align: center;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
}
</style>
<style>
.valid-error .ant-select-selection__placeholder {
  color: #f5222d;
}
.login-copyright {
  text-align: center;
  margin-top: 20px;
}
.login-copyright,
.login-copyright a {
  color: #666;
}
</style>
